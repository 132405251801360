import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Fade } from "react-awesome-reveal"

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`eco concious`, `moissanite`, `mining free`]}
        description={`Custom engagement rings and jewellery designs. Made with gold and conflict free, ethical precious stones (morganite, moissanite, sapphire, lab diamond, opal)`}
      />
      <div className="aboutwrap">
        <div className="abouttitle">The Dianne Rowe Story</div>
        <div className="aboutcontainer">
          <div className="imgleft"></div>
          <div className="abouttext">
            Embracing opportunity allows you to step outside of your comfort
            zone, take risks and dream big. It nurtures and nourishes ambition
            and allows you the courage to pursue the things that your heart most
            desires. With a wishful dream, a hopeful design and a simple
            question, the Dianne Rowe Private Collection was born.
          </div>
        </div>
        <Fade className="fadefix">
          <div className="aboutcontainer">
            <div className="abouttext2">
              The Rowe Collection draws inspiration from nature, timeless
              moments, precious memories and personal milestones. The
              magnificence of this collection is that each bespoke piece is just
              as breathtakingly unique as you are. We believe in creating
              something beautiful whilst also being conscious of our impact on
              both people and the environment. There's something so powerful
              about reinvention, whether it's the self, the soul or the path
              ahead.
            </div>
            <div className="imgright"></div>
          </div>
        </Fade>
        <Fade className="fadefix">
          <div className="aboutcontainer">
            <div className="imgleft2"></div>
            <div className="abouttext">
              At Dianne Rowe Private Collection, we pride ourselves on the
              conscious decision to only use mining and conflict free gemstones.
              Some of our bespoke and limited edition designs are delicately
              crafted with meticulously hand selected vintage gemstones. The use
              of ethically sourced gemstones negates the impact of open pit
              mining, environmental destruction, wildlife displacement, forced
              and child labour and human exploitation. At the centre of all our
              pieces we proudly feature moissanites, lab created diamonds and
              colourful precious gemstones that adorned the silhouettes of
              yesteryear.
            </div>
          </div>
        </Fade>
        <Fade className="fadefix">
          <div className="aboutcontainer">
            <div className="abouttext2">
              If the mundane bores you and your heart is set on crafting
              something otherworldly and purely exceptional, we are here to
              guide you through the creation of the jewellery of your dreams.
              Using our delicate bespoke design process, we take an agnostic
              approach no matter where you may be on this Earth we call home so
              you can enjoy luxury without the guilt. Fill out our obligation
              free contact form to unleash your creative potential and commence
              your bespoke journey today.
            </div>
            <div className="imgright2"></div>
          </div>
        </Fade>
        <Fade className="fadefix">
          <div className="aboutcontainer">
            <div className="imgleft3"></div>
            <div className="abouttext">
              <div className="aboutspecialtext">
                I hope these pieces inspire you and bring you happiness along
                your journey.
              </div>
              <div className="sigwrap">
                <div className="aboutsig" />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </Layout>
  )
}

export default AboutPage
